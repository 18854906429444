import React from 'react'
import { withSiteData } from '../../components/SiteData'
import AppLayout from "../../components/AppLayout";
import PageInfo from '../../components/PageInfo'
import FeatureContentPage from '../../components/FeatureContentPage'
import { SupportEmailAddress } from '../../components/EmailAddresses'
import { LegalCopy } from '../../components/common/Legal'

const LAST_MODIFIED_DATE = (<span>November 17, 2023</span>);
const CONTACT_INFO = (<SupportEmailAddress/>);
// From lawyer: DMCA's need to have not just email/form but also address & phone.
const EMAIL_ADDRESS_PHONE = (
	<span>
		<SupportEmailAddress/> or Moment Software LLC, 2121 Lohmans Crossing Rd, Ste 504-105, Lakeway, TX 78734 or ‭1-972-895-8087‬
	</span>
);

export default withSiteData(({ productName }) => (
	<AppLayout>
		<FeatureContentPage
			title={"Terms of Service and End-User License Agreement"}
		>
			<PageInfo
				title={"Terms of Service and End-User License Agreement - " + productName}
				description={"See the Terms of Service and End-User License Agreement for " + productName + " time tracker."}
			/>

			{/*TODO: make a class for this style & use it for the privacy policy as well*/}
			<LegalCopy>
				<p>
					This Terms of Service and End-User License Agreement (“Agreement”) is between Moment Software LLC (“Company”), a Texas limited liability Company and you (“Subscriber” or “you”) regarding the use of certain software.
				</p>
				<p>
					If you purchase a team subscription, create a user account, or use our software, you expressly agree to be bound, without modification, to this Agreement. If you do not agree to be bound to this Agreement, you cannot purchase a subscription to or use the software.
				</p>
				<p>
					If you are making a purchase on behalf of an organization, you warrant that you are authorized to bind that organization to legal agreements, and as such, that organization accepts this Agreement, and all the terms of this Agreement apply to that organization.
				</p>
				<p>
					Company reserves the right to change this Agreement at any time, at its sole discretion. If Company makes any changes, it will notify Subscribers at the email address provided by each Subscriber, and it will post any such changes here. You are responsible for reviewing any such amendments. Your continued use of the software after posting of an amended Agreement constitutes your acceptance of any such modified terms.
				</p>
				<p>
					This Agreement was last modified on {LAST_MODIFIED_DATE}.
				</p>
				<p>
					<strong>Software Services</strong>. Subject to the other terms and conditions of this Agreement, for as long as Subscriber has a subscription to the software, Subscriber will have access to the software, for the number of user seats purchased, for the term of Subscriber’s subscription. From time to time, Company may change, modify, or upgrade the functionality or appearance of the software, which may include the removal of functionality, content, or third-party integrations.
				</p>
				<p>
					<strong>Intellectual Property and License</strong>. Subject to the other terms and conditions of this Agreement, Company grants Subscriber a non-transferable, non-assignable, limited, non-exclusive, revocable license to use the software only as permitted per this Agreement, for as many user seats permitted per the Subscriber’s paid subscription, for the term of the Subscriber’s paid subscription. The password and login information that is assigned to Subscriber must be kept confidential, may only be used by Subscriber personally, and may not be shared, given, rented, or assigned to any other person. Company owns the copyrights, trademarks, and other intellectual property rights over the software licensed under this Agreement, and Subscriber agrees to abide by all copyright notices and trademark restrictions.
				</p>
				<p>
					<strong>Payments and Cancellation</strong>. You agree to pay the subscription fee as stated on the sales page of the software, via credit card (using Stripe payment processing), for the number of user seats you have selected. You understand that subscriptions automatically renew each month, and you agree that Company may process your payment automatically on the renewal date. You may cancel your Subscription at any time by logging into the Subscriber account and cancelling via the “Cancel Plan” option; if you cancel your subscription mid-billing-period you will not receive a refund but will have access to your account for the remainder of your billing period. Downgrades (lowering the number of user seats) take effect upon the next billing period; upgrades (increases of the number of user seats) take effect immediately and are pro-rated. Overdue amounts, including bounced payments or chargebacks, will be assessed a late payment charge at a monthly rate of 1.5% or the maximum provided by law, whichever is less. You understand that if a payment is late, charged-back, or declined for any reason, you will no longer have access to the software until the late payment is rectified. Company will have the right to recover expenses including collection costs and reasonable attorney's fees incurred in the collection of overdue amounts. You understand and agree that fees are non-refundable, and you are responsible for the payment even if you choose not to utilize the software (such as if you are not using one of your user seats). It is the Subscriber’s responsibility to adjust the size of their subscription.
				</p>
				<p>
					<strong>Your Content</strong>. Subscriber retains all intellectual property rights, including copyrights, over the content Subscriber uploads or cause to be uploaded to the software (“Subscriber Content”). Subscriber grants Company a non-exclusive, non-transferable (except as stated in this Agreement), world-wide, non-sublicensable, perpetual license to access, use, reproduce, electronically distribute, transmit, perform, format, display, store, archive, and index Subscriber Content for the purpose of your use of the software. You may export some of your data (reports and time logs) via your Subscriber account.
				</p>
				<p>
					<strong>Use Restrictions</strong>. Subscriber may not and may not allow others to: (a) sell, rent, lease, license, sublicense, or assign use of the software to others except as expressly provided in this Agreement; (b) reverse engineer, decompile, disassemble, or otherwise derive the source code from the software; (c) alter, modify, adapt, reconfigure, or prepare derivative works of the software; (d) copy, extract, summarize, distribute, or otherwise use the software in any manner which competes with or substitutes for Company’s distribution of the software to its customers; (e) use the software to violate the CAN-SPAM Act or the laws of any applicable jurisdiction; (f) use the software to abuse, defame, harass, threaten, or post illegal content; (g) use the software to transmit a virus, Trojan horse, worm, hack, or any harmful content; (h) use the software to gain unauthorized access to the software or any third-party platform; or (i) use the software to post content that infringes upon the copyright, trademark, trade secret, publicity rights, or other intellectual property interests of any other person or property. Subscriber will promptly notify Company if Subscriber’s account has been subject to a security breach, including disclosure of your username or password, or if Subscriber has had a security breach to any of Subscriber’s integrated third-party accounts.
				</p>
				<p>
					<strong>Term</strong>. This Agreement begins when you purchase a subscription and will continue for the term of your subscription or until terminated under this section, whichever is first. If either party materially breaches this Agreement, the other party may terminate this Agreement upon five (5) days written notice, unless the breach is cured within the notice period.
				</p>
				<p>
					<strong>No Guarantee of Results</strong>. The software is for your education and information. Company cannot and does not guarantee that any specific results will be obtained from the software such as specific goals regarding time management or success, because your results involve factors beyond the control of Company, including your experience and implementation.
				</p>
				<div style={{fontWeight: "bold", fontStyle: "italic"}}>
					<p>
						Disclaimer and Limitation of Liability. To the extent allowed by law, Company does not make any warranty regarding the software, including, but not limited to, the materials, the software, the content, the support, and/or the documentation, or any services or products provided through or in connection with the software, including integration with third-party software platforms. The software is licensed to the Subscriber “as-is" and “as-available,” without any warranty of any nature, express or implied, and Company expressly disclaims any and all warranties, including, without limitation: (a) any warranties as to the availability, accuracy, or completeness of the materials, software, content, support, documentation, information, products, or services which are part of the software; (b) warranties of fitness for a particular purpose, merchantability, or non-infringement, and (c) any warranty that the software will always be available, error-free, accessible, timely, or secure. Company disclaims any liability or responsibility for Subscriber Content or the behavior of third-party software platforms.
					</p>
					<p>
						In no event will Company, its subsidiaries, affiliates, licensors, employees, agents, or contractors be liable to the Subscriber for any damages or losses, including without limitation indirect, consequential, special, incidental or punitive damages resulting from or caused by the software, the materials, the content, the support, the documentation, or any errors or omissions in the software or the integration with third-party software platforms, even if Company is advised of the possibility of such damages.
					</p>
					<p>
						Any liability of Company, its subsidiaries, affiliates, licensors, employees, agents, or contractors, including without limitation any liability for damages caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, hacking, tampering, unauthorized use, communications line failure, theft, or destruction or unauthorized access to or alteration of the software or third-party software platforms, or use of records, whether for breach of contract, tortious behavior, negligence, or under any other cause of action, claims or damages, including, without limitation, damages for loss of business, business profits, business interruption, business information, data loss or corruption, or any other pecuniary loss arising out of or relating to the use of or the inability to use the software or third-party software platforms, shall be strictly limited to the amount paid to Company by or on behalf of the Subscriber in connection with the software in the three (3) months prior to the claimed injury or damage.
					</p>
					<p>
						Company does not represent or endorse the accuracy, current-ness, or reliability of any advice, opinion, statement or other information displayed, uploaded, or distributed through the software, including but not limited to, the materials, the software, the content, the support, and/or the documentation. Subscriber acknowledges that any reliance upon any such opinion, advice, statement, or information will be at subscriber’s sole risk. Some jurisdictions do not allow for some of the limitations or exclusions as set forth herein. Subscriber may have other rights which vary from jurisdiction to jurisdiction.
					</p>
				</div>
				<p>
					<strong>Your Warranties and Indemnification</strong>. You warrant that any materials you upload to the software do not infringe upon the copyright, trademark, trade secret, publicity rights, or other intellectual property interests of any other person or property, and are not in violation of any laws or regulations of any applicable jurisdiction. You agree to indemnify Company against all claims and damages arising out of your breach or alleged breach out of any representations, warranties, or agreements made by you under this Agreement, and your participation in any purchased software.
				</p>
				<p>
					<strong>Force Majeure</strong>. Neither Party will be liable hereunder for any failure or delay in the performance of its obligations under this Agreement if such failure or delay is on account of causes beyond its control, including labor disputes, civil commotion, war, fires, floods, inclement weather, governmental regulations or controls, casualty, government authority, strikes, terrorism, hacker attacks, widespread internet outages, or acts of God, in which event the non-performing party will be excused from its obligations for the period of the delay and for a commercially reasonable time thereafter.
				</p>
				<p>
					<strong>No Waiver</strong>. The terms of this Agreement cannot be waived or modified except by an express agreement in writing signed by all the parties. The failure of any party at any time to exercise a right or enforce a provision of this Agreement will not limit that party’s right to exercise the right or enforce the provision, nor will any waiver of any breach of any provision constitute a waiver of that provision itself.
				</p>
				<p>
					<strong>No Assignment or Transfer</strong>. You may not assign, sub-license, or transfer this Agreement (including your login/password information) to any other person or entity, except that Subscriber may re-assign a user seat to another user under its control. Sharing your login information with any other person (except as provided above) is a material breach of this Agreement.
				</p>
				<p>
					<strong>Entire Agreement</strong>. This Agreement constitutes the entire agreement between the parties and supersedes all prior or contemporaneous written or oral agreements between the parties with respect to the subject matter contained in this Agreement.
				</p>
				<p>
					<strong>Severability</strong>. If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the validity, legality, or enforceability of the remainder of this Agreement will not in any way be affected or impaired.
				</p>
				<p>
					<strong>Choice of Law and Arbitration</strong>.
					{' '}
					<span style={{fontStyle: "italic"}}>
						Any controversy or claim arising out of or relating to this contract, or the breach thereof, will be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The arbitration will be governed by the laws of the State of Texas, the United States of America without regard to the principles of conflict of laws. The United Nations Convention on Contracts for the International Sale of Goods does not apply. Each party will, upon written request of the other party, promptly provide the other with copies of all relevant documents. There will be no other discovery allowed. The arbitration will be based on the submission of documents and there will be no in-person or oral hearing. Time is of the essence for any arbitration under this Agreement and arbitration hearings will take place within 90 days of filing and awards rendered within 120 days. Arbitrator(s) will agree to these limits prior to accepting appointment. The arbitrators will have no authority to award punitive or other damages not measured by the prevailing party's actual damages, except as may be required by statute. The arbitrator(s) will not award consequential damages in any arbitration initiated under this section. The prevailing party will be entitled to an award of reasonable attorney fees. Except as may be required by law, neither a party nor an arbitrator may disclose the existence, content, or results of any arbitration hereunder without the prior written consent of both parties.
					</span>
				</p>
				<p>
					<strong>Notices</strong>. You agree that Company may provide notice to you under this Agreement via the email you provided in your Subscriber account, or by placing a banner across the web pages of the software application. You agree that you are liable and responsible to keep your account information accurate and up to date. You may provide legal notice to Company via {CONTACT_INFO}.
				</p>
				<div>
					<p>
						<strong>Reporting Copyright Infringement (DMCA)</strong>. Company complies with the terms of the Digital Millennium Copyright Act. To report a claim of copyright infringement, please send a claim to our official agent at {EMAIL_ADDRESS_PHONE}, containing the following information:
					</p>
					<ul>
						<li>Your contact information (name, mailing address, phone number).</li>
						<li>A description of the copyrighted work that you believe was infringed.</li>
						<li>A description of the content on this site that you believe is infringing your copyright. If possible, please include a web address/link.</li>
						<li>A declaration that: you have a good faith belief that the use of the copyrighted materials described was not authorized by the copyright owner, agent, or the law; that the information in your notice is accurate, and that you declare, under penalty of perjury, that you are the owner or the allegedly infringed copyright or you are authorized to act on behalf of the copyright owner.</li>
						<li>Your digital or physical signature.</li>
					</ul>
				</div>
				<p>
					<strong>Questions</strong>. Any questions about the Agreement can be directed to: {EMAIL_ADDRESS_PHONE}.
				</p>
				<div>
					<p>
						<strong>Terms Applicable to Software Made Available through the App Store from Apple, Inc.</strong> If Subscriber downloads software made available by Company via the App Store, the following terms apply:
					</p>
					<ul>
						<li><strong>Acknowledgement</strong>. The parties acknowledge that the Agreement is between Company and the Subscriber only, and not with Apple, and Company, not Apple, is solely responsible for the software and the content thereof.</li>
						<li><strong>Scope of License</strong>. The license granted to Subscriber to use the software downloaded from the App Store is limited to a non-transferable license to use the software downloaded from the App Store on any Apple-branded Product that the Subscriber owns or controls and as permitted by the Usage Rules set forth in the App Store Terms of Service, except that such software downloaded from the App Store may be accessed and used by other accounts associated with the purchaser via Family Sharing or volume purchasing.</li>
						<li><strong>Maintenance and Support</strong>. The parties acknowledge that Company, not Apple, is responsible for maintenance and support of the software downloaded from the App Store. Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the software downloaded from the App Store.</li>
						<li><strong>Warranty</strong>. In the event of any failure of the software downloaded from the App Store to conform to any applicable warranty, the Subscriber may notify Apple, and Apple will refund the purchase price for the software downloaded from the App Store to Subscriber; and that, to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the software downloaded from the App Store, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty is Company’s sole responsibility.</li>
						<li><strong>Product Claims</strong>. The parties acknowledge that Company, not Apple, is responsible for addressing any claims of the Subscriber or any third party relating to the software downloaded from the App Store or the Subscriber’s possession and/or use of that the software downloaded from the App Store, including, but not limited to: (i) product liability claims; (ii) any claim that the software downloaded from the App Store fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection, privacy, or similar legislation, including in connection with the software downloaded from the App Store’s use of the HealthKit and HomeKit frameworks.</li>
						<li><strong>Intellectual Property Rights</strong>. The parties acknowledge that, in the event of any third party claim that the software downloaded from the App Store or the Subscriber’s possession and use of the software downloaded from the App Store infringes that third party’s intellectual property rights, Company, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</li>
						<li><strong>Legal Compliance</strong>. The Subscriber must represent and warrant that (i) he/she is not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) he/she is not listed on any U.S. Government list of prohibited or restricted parties.</li>
						<li><strong>Third Party Terms of Agreement</strong>. Subscriber must comply with applicable third party terms of agreement when using the software downloaded from the App Store.</li>
						<li><strong>Third Party Beneficiary</strong>. The parties acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of this Agreement, and that, upon Subscriber’s acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against the Subscriber as a third party beneficiary thereof.</li>
					</ul>
				</div>

			</LegalCopy>

		</FeatureContentPage>
	</AppLayout>
));
