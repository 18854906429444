import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';
import FeaturePageIntro from './FeaturePageIntro'

export default function FeatureContentPage({ title, description, className, children }) {
	return (
		<div className={cn(className, "tt-screen-content")}>
			<FeaturePageIntro title={title} description={description}/>

			<div className="tt-screen-content">
				{children}
			</div>

		</div>
	);
}
FeatureContentPage.propTypes = {
	title: PropTypes.any,
	description: PropTypes.any,
	className: PropTypes.string,
	children: PropTypes.any,
}
