import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import mountainLeftImg from '../../images/bg/mountainLeft.svg'
import mountainRightImg from '../../images/bg/mountainRight.svg'

const useMountainBgStyles = makeStyles(theme => ({
	bgLeft: {
		background: "url(" + mountainLeftImg + ") 0% 0% / cover no-repeat scroll content-box border-box rgba(0,0,0,0)",
	},
	bgRight: {
		background: "url(" + mountainRightImg + ") 100% 0% / cover no-repeat scroll content-box border-box rgba(0,0,0,0)",
	},
}), {name: "MountainBg"});

export function MountainBg(props) {
	let {align, children} = props;
	const classes = useMountainBgStyles();
	return (
		<div className={align === "right" ? classes.bgRight : classes.bgLeft}>
			{children}
		</div>
	);
}
MountainBg.propTypes = {
	/** alignment, default 'left' */
	align: PropTypes.oneOf(['left', 'right']),
	children: PropTypes.any,
};
