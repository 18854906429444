import React from 'react'

const EMAIL_DOMAIN = "moment-software.com";

/**
 * Render the support email address, but not until after the component has mounted so that bots will not pick it up
 */
export class SupportEmailAddress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contactInfo: "[loading contact info...]",
		};
	}

	componentDidMount() {
		const user = "support";
		this.setState({
			// Do this to avoid putting our email in the statically generated page
			contactInfo: user + "@" + EMAIL_DOMAIN,
		});
	}

	render() {
		let {contactInfo} = this.state;
		return (
			contactInfo
		);
	}
}
