import React from 'react'
import PropTypes from 'prop-types'
import { FeatureHeader } from './FeatureDisplay'

export default function FeaturePageIntro({ id, title, description }) {
	return (
		<div id={id} className="container-fluid px-sm-5">
			{title && (<FeatureHeader>{title}</FeatureHeader>)}
			{description}
		</div>
	)
}
FeaturePageIntro.propTypes = {
	id: PropTypes.string,
	title: PropTypes.any,
	description: PropTypes.any
}
