import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useLegalCopyStyles = makeStyles(theme => ({
	legalCopy: {
		"& h2": {
			fontWeight: "bold",
			marginTop: "1.4em",
			marginBottom: "0",
			fontSize: theme.typography.pxToRem(18),
		}
	},
}), {name: "LegalCopy"});

/**
 * A container for legal copy.
 */
export function LegalCopy(props) {
	let {children} = props;
	const classes = useLegalCopyStyles();
	return (
		<div className={cn("container-fluid px-sm-5 mb-5", classes.legalCopy)}>
			{children}
		</div>
	);
}
LegalCopy.propTypes = {
	children: PropTypes.any.isRequired,
};
