import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { MountainBg } from './common/BackgroundDecorators'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Spacing from '../reused-libraries/Spacing'

function FeatureSeparator(props) {
	return (
		<div className="px-0 px-md-5">
			<div style={{borderBottom: "1px solid #bababa"}}/>
		</div>
	);
}

const useFeatureDisplayStyles = makeStyles(theme => ({
	bgEven: {
		// backgroundColor: "#f7f7f7",
		// backgroundColor: fade(theme.palette.primary.main, 0.02),
	},
	featureDisplayImageContainer: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: "24px",
			paddingRight: "24px",
		},
		textAlign: "center",
	},
	textContainer: {
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
}), {name: "FeatureDisplay"});

export default function FeatureDisplay(props) {
	const { header, odd, imgPlacement, imgGridLg = 8, img, children } = props;

	const classes = useFeatureDisplayStyles();
	const theme = useTheme();
	const isMdPlus = useMediaQuery(theme.breakpoints.up('md'));
	const isLgPlus = useMediaQuery(theme.breakpoints.up('lg'));

	const imgLeft = imgPlacement === 'left';
	const imgRight = imgPlacement === 'right';
	const imgTop = !imgLeft && !imgRight;

	const textBgOnly = imgTop || !isLgPlus;
	const BgContainer = ({children}) => <MountainBg align={odd ? "right" : "left"} children={children}/>;
	const AllBgContainer = !textBgOnly ? BgContainer : React.Fragment;
	const TextBgContainer = textBgOnly ? BgContainer : React.Fragment;

	return (
		<>
			{!odd && (<FeatureSeparator/>)}
			<Spacing py={isMdPlus ? 5 : 3} mx={isMdPlus ? 5 : 0} className={cx({[classes.bgEven]: !odd})}>
				<AllBgContainer>
					<Spacing mt={isMdPlus ? 0 : 2} mb={5}>
						<Typography variant="h2" className="text-center">
							{header}
						</Typography>
					</Spacing>
					<Grid container className="mx-0">
						<Grid item xs={12} lg={imgTop ? 12 : imgGridLg} className={cx({'order-last': imgRight && isLgPlus})}>
							<div className={classes.featureDisplayImageContainer}>
								{img}
							</div>
						</Grid>
						<Grid item xs={12} lg={imgTop ? 12 : 12 - imgGridLg}>
							<div className={classes.textContainer}>
								<TextBgContainer>
									<Spacing pt={isLgPlus && !imgTop ? 0 : 2} px={6}>
										{children}
									</Spacing>
								</TextBgContainer>
							</div>
						</Grid>
					</Grid>
				</AllBgContainer>
			</Spacing>
			{!odd && (<FeatureSeparator/>)}
		</>
	);
}
FeatureDisplay.propTypes = {
	header: PropTypes.any,
	/** true if this is an odd row, or false if it is even */
	odd: PropTypes.bool,
	/** the image placement, default 'top' */
	imgPlacement: PropTypes.oneOf(['top', 'left', 'right']),
	/** The grid item size for the image between 1-11 in size "lg" when the placement is not "top". Default is 8 */
	imgGridLg: PropTypes.number,
	/** The image to display */
	img: PropTypes.node,
	/** The content describing the image */
	children: PropTypes.any.isRequired,
}

const useFeatureDisplayImageStyles = makeStyles(theme => ({
	displayImg: {
		boxShadow: "0 2px 18px 2px rgba(0,0,0,0.6)",
	},
}), {name: "FeatureDisplayImage"});

export function useFeatureDisplayImageClassName() {
	const classes = useFeatureDisplayImageStyles();
	return classes.displayImg;
}

export function FeatureDisplayImage(props) {
	let {imgData, alt} = props;
	const className = useFeatureDisplayImageClassName();
	return (
		<GatsbyImage alt={alt} image={imgData} className={className}/>
	);
}
FeatureDisplayImage.propTypes = {
	/** The image data */
	imgData: PropTypes.object.isRequired,
	/** the alt text for the image */
	alt: PropTypes.string,
};

const useFeatureHeaderStyles = makeStyles(theme => ({
	extraMargin: {
		marginBottom: "1rem",
		[theme.breakpoints.down('sm')]: {
			marginTop: "3rem",
		},
	},
}), {name: "FeatureHeader"});

export function FeatureHeader(props) {
	const {variant = "h1", component, extraMargin, className, children} = props;
	const classes = useFeatureHeaderStyles();
	return (
		<Typography variant={variant} gutterBottom component={component} className={cx({[classes.extraMargin]: extraMargin}, className)}>
			{children}
		</Typography>
	)
}
FeatureHeader.propTypes = {
	/** Type of typography variant to use, is 'h1' if not specified */
	variant: PropTypes.oneOf(['h1','h2','h3','h4','h5','h6','subtitle1','subtitle2','body1','body2','caption','button','overline','srOnly','inherit']),
	/** Type of component to use */
	component: PropTypes.elementType,
	/** true to add extra bottom margin, plus if it's a small screen, add top margin */
	extraMargin: PropTypes.bool,
	/** Optional CSS classes */
	className: PropTypes.string,
	/** the header content */
	children: PropTypes.any,
}

const useFeatureTextStyles = makeStyles(theme => ({
	featureDescription: {
		fontSize: "1.35rem",
		marginBottom: "1rem",
	},
}), {name: "FeatureText"});

export function FeatureText(props) {
	const { description, small, children } = props;
	const classes = useFeatureTextStyles();
	return (
		<div>
			<div className={cx({[classes.featureDescription]: !small})}>{description}</div>
			{children && (
				<div className="pt-sm-2">{children}</div>
			)}
		</div>
	)
}
FeatureText.propTypes = {
	description: PropTypes.any,
	small: PropTypes.bool,
	children: PropTypes.any,
}
